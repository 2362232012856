.nav{
    background: #ededed;
    border-bottom: 1px solid white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

#nav-link{
    color: black;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 0 3rem;
    text-decoration: none;
}

#nav-link:hover{
    color: grey;    
}

#nav-hashlink{
    display: block;
    color: black;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 0 3rem;
    margin: auto;
    text-decoration: none;
}

#nav-brand{
    margin-left: 30px;
}
#nav-hashlink:hover{
    color: grey; 
}

#nav-toggle{
    margin-right: 30px;
    border: none;
}


  @media screen and (min-width: 280px) {

    #nav-brand{
        width: 190px;
    }

  }

  @media screen and (min-width: 330px) {

    #nav-brand{
        width: 230px;
    }

  }
  @media screen and (min-width: 800px) {
    #nav-brand{
        height: 60%;
        width: 60%;
    }
    
    #nav-link{
        font-size: 15px;
    }
  }