.area{
    width:70%;
    margin: auto;
    height: 100%;
    padding-top: 50px;
}
  
.area-content h1 {
    font-size: 35px;
    padding: 13px;
    font-family: "Roboto";
    font-weight: 600;

  }
  
.area-content h4 {
    font-size: 13px;
    padding: 15px;
    letter-spacing: .5px;
    font-family: "Roboto";
}

.form-box{
    /*min-width: 100%;*/
    height: 60vh;
    /*position:relative;*/ 
    margin: auto;
}

.form-box input, textarea{
    display: block;
    width: 100%;
    padding: .5rem .8rem .5rem .8rem;
    margin: .7vw 0 ;
    border: 1px solid #adadad;
    border-radius: 5px;
    font-size: 20px;
}
.form-box textarea{
    height: 15vh;
}

.form-box h5{
    font-size: 35px ;
    text-align: center;
    /* color: #F55A00; */
    margin: 0 0 .5vh 0;
}

.form-box button{
    width: 40%;
    padding: .5rem .8rem .5rem .8rem;
    margin: .9vw 0 ;
    border: 1px solid #adadad;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    display: block;
}

.form-box button:hover{
    background-color: #a9fc57;
    transition: 1s background ease;
}

@media screen and (min-width: 280px) {
    .area {
      width: 60vw;
      height: 130vh;
      padding-top: 50%;
    }

    .area-content h1 {
        font-size: 20px;
      }

    .area-content h4 {
        font-size: 13px;
        padding: 15px;
        letter-spacing: .5px;
        font-family: "Roboto";
    }

    .form-box input, textarea{
        font-size: 15px;
        height: 5vh;
    }

    .form-box textarea{
        height: 20vh;
    }

  }

  @media screen and (min-width: 420px) {
    .area {
      padding-top: 10%;
      height: 130vh;
    }

  }

  @media screen and (min-width: 540px) {
    .area{
        height: 100vh;
        padding-top: 20%;
    }
    .area-content h1{
        font-size: 30px;
    }
    .area-content h4{
        font-size: 15px;
    }
    .form-box input, textarea{
        font-size: 15px;
        height: 100%;
    }
}

@media screen and (min-width: 800px) {
    .area {
      width: 50vw;
      padding-top: 10%;
      height: 140vh;
    }
}

@media screen and (min-width: 1500px) {
    .area {
      height: 100%;
      padding-top: 5%;
    }

    .form-box input, textarea{
        font-size: 19px;
    }

    .area-content h4 {
        font-size: 19px;
        padding: 15px;
        letter-spacing: .5px;
        font-family: "Roboto";
    }
}