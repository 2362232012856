.about-us-info {
    height: 100vh;
    width: 40%;
    margin: auto;
    font-family: "Roboto";
    text-align: left;
    padding-top: 50px;
}
  
.about-us-content h1 {
    font-size: 35px;
    padding: 13px;
    font-family: "Roboto";
    font-weight: 600;
    text-align: center;
}

.about-us-content h4 {
    font-size: 19px;
    padding: 15px;
    letter-spacing: .5px;
}

@media screen and (min-width: 280px) {
    .about-us-info {
      width: 80vw;
      padding-top: 50%;
      height: 110vh;
    }

    .about-us-content{
        padding: 0vh 9px;
    }

    .about-us-content h1{
        font-size: 20px;
    }
    .about-us-content h4{
        font-size: 12px;
    }

  }

  @media screen and (min-width: 420px) {
    .about-us-info {
      width: 80vw;
      padding-top: 10%;
    }

    .about-us-content{
        padding: 0vh 9px;
    }

    .about-us-content h1{
        font-size: 20px;
    }
    .about-us-content h4{
        font-size: 12px;
    }

  }

  @media screen and (min-width: 540px) {
    .about-us-info {
      padding-top: 30%;
    }
    .about-us-content h1{
      font-size: 30px;
    }
    .about-us-content h4{
      font-size: 15px;
    }
  }

  @media screen and (min-width: 800px) {
    .about-us-info {
      width: 50vw;
      padding-top: 10%;
    }
  }


  @media screen and (min-width: 1500px) {
    .about-us-info {
      width: 60%;
      padding-top: 10%;
    }

    .about-us-content h1{
        font-size: 35px;
    }
    .about-us-content h4{
        font-size: 19px;
    }
  }