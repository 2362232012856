.support {
    /*background: rgb(0,0,0);
    background: linear-gradient(138deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(214,254,174,1) 100%);*/
    height: 100vh;
    /*background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(252,255,249,1) 58%, rgba(169,252,87,1) 100%);*/
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(252,255,249,1) 70%, rgba(169,252,87,1) 100%);
    display: flex;
}

.support-info {
    height: 100%;
    width: 30%;
    margin-left: 50px;
    font-family: "Roboto";
    text-align: left;
}

.support-content {
    padding: 22vh 9px;
  }
  
.support-content h1 {
    font-size: 45px;
    padding: 13px;
  }
  
  .support-content h4 {
    font-size: 19px;
    padding: 15px;
    letter-spacing: .5px;
  }


  @media screen and (min-width: 280px) {
    
    .support-info {
        width: 80vw;
        padding-top: 20%
    }

    .support-content h1{
        font-size: 20px;
        padding: 0;
    }
    .support-content h4{
        font-size: 12px;
        padding: 0;
    }

    .support-content-image{
        display: none;
    }

    #support-image{
        display: none;
      }

  }

  @media screen and (min-width: 540px) {
    .support-info{
      padding: 0;
    }
    .support-content h1{
        font-size: 30px;
    }
    .support-content h4{
        font-size: 15px;
    }
}

@media screen and (min-width: 800px) {
    .support-info {
        width: 50vw;
        padding-top: 4%;
    }
    .support-content-image{
        margin: auto;
        padding-top: 4%;
        display: flex;
      }
      #support-image{
        display: flex;
        width: 300px;
        border-radius: 20px; 
        border: 10px solid transparent;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      }
}

@media screen and (min-height: 1300px) and (min-width:1000px){
    .support-content-image{
        margin: auto;
        padding-bottom: 30%;
        display: flex;
      }
      #support-image{
        display: flex;
        width: 300px;
        border-radius: 20px; 
        border: 10px solid transparent;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      }
}

  @media screen and (min-width: 1500px) {
    .support-info {
        width: 50%;
    }
    .support-content{
        padding: 22vh 9px;
    }

    .support-content h1{
        font-size: 35px;
    }
    .support-content h4{
        font-size: 19px;
    }
    .support-content-image{
        margin: auto;
        padding-bottom: 12%;
    }
    #support-image{
        display: flex;
        height: 100%;
        width: 500px;
        border-radius: 20px; 
        border: 10px solid transparent;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }