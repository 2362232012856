.licenses {
    height: 100vh;
    /*background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(252,255,249,1) 58%, rgba(169,252,87,1) 100%);*/
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(252,255,249,1) 70%, rgba(169,252,87,1) 100%);
    display: flex;
}

.licenses-info {
    height: 100%;
    width: 30%;
    margin-left: 50px;
    font-family: "Roboto";
    text-align: left;
}

.licenses-content {
    padding: 22vh 9px;
  }
  
  .licenses-content h1 {
    font-size: 45px;
    padding: 13px;
  }
  
  .licenses-content h4 {
    font-size: 19px;
    padding: 15px;
    letter-spacing: .5px;
  }
  


@media screen and (min-width: 280px) {
    .licenses-info {
      width: 80vw;
      padding-top: 50%;
    }

    .licenses-content h1{
        font-size: 20px;
        padding: 0;
    }
    .licenses-content h4{
        font-size: 12px;
        padding: 0;
    }

    .licenses-content-image{
      display: none;
    }

    #licenses-image{
      display: none;
    }
  }

  @media screen and (min-width: 540px) {
    .licenses-info {
      padding-top: 30%;
    }
    .licenses-content h1{
        font-size: 30px;
    }
    .licenses-content h4{
        font-size: 15px;
    }
}

  @media screen and (min-width: 800px) {
    .licenses-info {
      width: 50vw;
      padding-top: 10%;
    }
    .licenses-content-image{
      display: flex;
      margin-left: 10%;
      margin-right: 3%;
      margin: auto;
      padding-top: 10%;
    }
    #licenses-image{
      display: flex;
      width: 300px;
      border-radius: 20px; 
      border: 10px solid transparent;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (min-height: 1300px) and (min-width:1000px){
    .licenses-content-image{
        margin: auto;
        padding-bottom: 45%;
        display: flex;
      }
      #licenses-image{
        display: flex;
        width: 300px;
        border-radius: 20px; 
        border: 10px solid transparent;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      }
  }

  @media screen and (min-width: 1500px) {
    .licenses-info {
      width: 50%;
  }
  .licenses-content{
      padding: 22vh 9px;
  }

  .licenses-content h1{
      font-size: 35px;
  }
  .licenses-content h4{
      font-size: 19px;
  }
  .licenses-content-image{
    margin: auto;
    padding-bottom: 13%;
}
#licenses-image{
  display: flex;
  height: 100%;
  width: 500px;
  border-radius: 20px; 
  border: 10px solid transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
}