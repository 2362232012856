.main-footer {
    color: black;
    background-color: #ededed;
    position: "relative";
    bottom: 0;
    width: 100%;
    border-top: 1px solid white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

.main-footer img{
    width: 7%;
    margin: auto;
}

.footer-text{
    font-size: 12px;
    color: grey;
}

@media screen and (min-width: 280px) {
    .main-footer {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .main-footer {
        display: block;
    }
}